import request from '@/utils/request'

/** 查询小区数据列表 */
export function listGarden(query) {
  return request({
    url: '/system/garden/list',
    method: 'get',
    params: query,
    headers: {
      isToken: false
    }
  })
}


// 查询小区数据详细
export function getGardenData(dataId) {
  return request({
    url: '/system/garden/' + dataId,
    method: 'get'
  })
}

// 批量修改
export function batchGarden(data) {
  return request({
    url: '/system/garden/batch',
    method: 'put',
    data: data
  })
}
