<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">安全防控</div>
    <div class="idx-middle-2">
      <div class="b-left" id="anbao"></div>
      <div class="b-cent" id="work"></div>
      <div class="b-right" id="baojie"></div>
    </div>
    <div class="clear"></div>
    <div class="idx-middle-3">
      <div class="idx-Lbox-2-3">
        <div class="idx-Lbox-2-3-1">
          <span>治安岗亭</span>
          <span>{{ dataOptions.zagt }}</span>
        </div>
        <div class="idx-Lbox-2-3-1">
          <span>消火栓</span>
          <span>{{ dataOptions.xhs }}</span>
        </div>
        <div class="idx-Lbox-2-3-1">
          <span>监控室</span>
          <span>{{ dataOptions.jks }}</span>
        </div>
        <div class="idx-Lbox-2-3-1 idx-Lbox-2-3-last">
          <span>监控头</span>
          <span>{{ dataOptions.jkt }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  watch: {
    dataOptions: {
      handler: function (dataOptions) {
        if (dataOptions.abry) {
          this.anbaoData();
          this.workData();
          this.baojieData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    // 安全防控——————————————————————————
    // 安保人员
    anbaoData() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("anbao"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#36DD69",
          xAxis: ["安保人员"],
          values: [this.dataOptions.abry],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            //   top: "77%",
            top: "77%",

            textAlign: "center",
            textStyle: {
              fontSize: "12",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            //   radius: ["75%", "85%"],
            radius: ["75%", "85%"],
            //  center: [50 * (index + 1) + "%", "45%"],
            center: [50 * (index + 1) + "%", "45%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 100 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 12,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("anbao"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#36DD69",
          xAxis: ["安保人员"],
          values: [this.dataOptions.abry],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            //   top: "77%",
            top: "77%",

            textAlign: "center",
            textStyle: {
              fontSize: "14",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            //   radius: ["75%", "85%"],
            radius: ["75%", "85%"],
            //  center: [50 * (index + 1) + "%", "45%"],
            center: [50 * (index + 1) + "%", "45%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 100 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 14,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    // 工作人员
    workData() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("work"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#55ECF3",
          xAxis: ["工作人员"],
          values: [this.dataOptions.gzry],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "77%",

            textAlign: "center",
            textStyle: {
              fontSize: "12",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["75%", "85%"],
            center: [50 * (index + 1) + "%", "45%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 100 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 12,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("work"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#55ECF3",
          xAxis: ["工作人员"],
          values: [this.dataOptions.gzry],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "77%",

            textAlign: "center",
            textStyle: {
              fontSize: "14",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["75%", "85%"],
            center: [50 * (index + 1) + "%", "45%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 100 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 14,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    // 保洁人员
    baojieData() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("baojie"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#F5893D",
          xAxis: ["保洁人员"],
          values: [this.dataOptions.bjry],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "77%",

            textAlign: "center",
            textStyle: {
              fontSize: "12",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["75%", "85%"],
            center: [50 * (index + 1) + "%", "45%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 100 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 12,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("baojie"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#F5893D",
          xAxis: ["保洁人员"],
          values: [this.dataOptions.bjry],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "77%",

            textAlign: "center",
            textStyle: {
              fontSize: "14",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["75%", "85%"],
            center: [50 * (index + 1) + "%", "45%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 100 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 14,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    // this.anbaoData();
    // this.workData();
    // this.baojieData();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 30vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
    width: 100%;
    height: 24vh;
    // border: 1px solid tan;
    overflow: hidden;
    // display: none;
    #anbao {
      float: left;
      width: 33.3%;
      height: 24vh;
      margin-top: 0.9vh;
    }
    #work {
      float: left;
      width: 33.3%;
      height: 24vh;
      margin-top: 0.9vh;
    }
    #baojie {
      float: left;
      width: 33.3%;
      height: 24vh;
      margin-top: 0.9vh;
    }
  }
  .idx-middle-3 {
    width: 100%;
    height: 10vh;
    // border: 1px solid tan;
    position: absolute;
    left: 0vw;
    right: 0vw;
    bottom: 4vh;
    z-index: 99;
    .idx-Lbox-2-3 {
      width: 100%;
      height: 7vh;
      margin-top: 2vh;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .idx-Lbox-2-3-1 {
        width: 16.7%;
        height: 5.7vh;
        float: left;
        background: linear-gradient(183deg, #145dca, #1634c0);
        border-radius: 0.4vw;
        /* margin: 0 2.6667vw; */
        cursor: pointer;
        position: relative;
        span {
          color: #fff;
          width: 100%;
          display: inline-block;
          padding-top: 0.65vh;
          text-align: center;
          &:first-child {
            margin-top: 0vh;
            font-size: 1.4vh;
          }
          &:last-child {
            color: #00a8fe;
            font-size: 1.6vh;
            font-weight: bold;
            position: absolute;
            left: 0;
            top: 2.5vh;
          }
        }
        // span:first-child {
        //   margin-top: 0vh;
        //   font-size: 1.4vh;
        // }
        // span:last-child {
        //   color: #00a8fe;
        //   font-size: 1.6vh;
        //   font-weight: bold;
        //   position: absolute;
        //   left: 0;
        //   top: 2.5vh;
        // }
      }
      .idx-Lbox-2-3-last {
        margin-right: 0vw;
      }
    }
  }
}
</style>
