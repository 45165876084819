<template>
  <div class="sun-map-view">
    <div class="title">{{ $route.query.mapName }}</div>
    <div class="angle tl"></div>
    <div class="angle tr"></div>
    <div class="angle bl"></div>
    <div class="angle br"></div>
    <BmMapxq
      :refresh="refresh"
      :markers="markers"
      :marker-type="markerType"
      :center="center"
    ></BmMapxq>
  </div>
</template>

<script>
import BmMapxq from "@/components/bmMapxq";
export default {
  name: "sun-map",
  components: {
    BmMapxq,
  },
  data() {
    return {
      center: { lng: 1000, lat: 0 },
      markers: [],
      markerType: "xqzb",
      refresh: 0,
    };
  },
  created() {
    this.markers = [this.$route.query];
  },
};
</script>

<style scoped lang="scss">
.sun-map-view {
  width: 100%;
  height: 45vh;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border: 2px solid #04f3ee;
  box-sizing: border-box;
  //opacity: 0.4;
  .title {
    position: absolute;
    background: url("~@/assets/image/title-bg.png") no-repeat;
    background-size: 100% 100%;
    width: 17.6vw;
    left: 50%;
    top: -2px;
    margin-left: -8.8vw;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    color: #ffd901;
    z-index: 2;
  }
  .angle {
    position: absolute;
    width: 4vh;
    height: 4vh;
    z-index: 3;
    background: url("~@/assets/image/angle-line.png");
    background-size: 100% 100%;

    &.tr {
      right: 0;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* IE 9 */
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg); /* Opera */
    }
    &.br {
      right: 0;
      bottom: 0;
      transform: rotate(180deg);
      -ms-transform: rotate(180deg); /* IE 9 */
      -moz-transform: rotate(180deg); /* Firefox */
      -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
      -o-transform: rotate(180deg); /* Opera */
    }
    &.bl {
      bottom: 0;
      transform: rotate(-90deg);
      -ms-transform: rotate(-90deg); /* IE 9 */
      -moz-transform: rotate(-90deg); /* Firefox */
      -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
      -o-transform: rotate(-90deg); /* Opera */
    }
  }
}
</style>
