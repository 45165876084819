<template>
  <div class="xqu-middle-box">
    <div class="xqu-middle-1">社区空间</div>
    <div class="xqu-middle-2box">
      <div class="xqu-middle-2box-1" @click="() => this.$parent.getInfo('sqkj_mj')">
        <div class="xqu-box1">
          <div class="xqu-box1-1">辖区面积(k㎡)</div>
          <div class="xqu-box1-2">
            <img class="xqu-box1-image" src="@/assets/icon/shequ-1.png" alt="" />
            <span>{{dataOptions.xqmj}}</span>
          </div>
        </div>
        <div class="xqu-box1">
          <div class="xqu-box1-1">建筑面积(k㎡)</div>
          <div class="xqu-box1-2">
            <img class="xqu-box1-image" src="@/assets/icon/shequ-2.png" alt="" />
            <span>{{dataOptions.jzmj}}</span>
          </div>
        </div>
        <div class="xqu-box1">
          <div class="xqu-box1-1">绿化面积(k㎡)</div>
          <div class="xqu-box1-2">
            <img class="xqu-box1-image" src="@/assets/icon/shequ-3.png" alt="" />
            <span>{{dataOptions.lhmj}}</span>
          </div>
        </div>
      </div>
      <div class="xqu-middle-2box-2" @click="() => this.$parent.getInfo('sqkj_ds')">
        <div class="xqu-2box-2a-1">
          <div class="xqu-2box-2a-1a">
            <div class="xqu-2box-2a-1aL">
              <img class="xqu-box1-image" src="@/assets/icon/shequ-4.png" alt="" />
            </div>
            <div class="xqu-2box-2a-1aR">
              <span>建筑栋数</span>
              <span>{{dataOptions.jzds}}</span>
            </div>
          </div>
          <div class="xqu-2box-2a-1b">
            <div class="xqu-2box-2a-1box">
              <span>( </span>
              <span>高层：</span>
              <span class="xqu-s1">{{dataOptions.jzdsGc}}</span>
              <span>别墅：</span>
              <span class="xqu-s1">{{dataOptions.jzdsBs}}</span>
              <span>其它：</span>
              <span class="xqu-s1">{{dataOptions.jzdsQt}}</span>
              <span> )</span>
            </div>
          </div>
        </div>
        <div class="xqu-2box-2a-1">
          <div class="xqu-2box-2a-1a">
            <div class="xqu-2box-2a-1aL">
              <img class="xqu-box1-image" src="@/assets/icon/shequ-5.png" alt="" />
            </div>
            <div class="xqu-2box-2a-1aR">
              <span>总户数</span>
              <span>{{dataOptions.zhs}}</span>
            </div>
          </div>
          <div class="xqu-2box-2a-1b">
            <div class="xqu-2box-2a-1box">
              <span class="xqu-s1a">( </span>
              <span>住宅：</span>
              <span class="xqu-s1">{{dataOptions.zhsZz}}</span>
              <span>商铺：</span>
              <span class="xqu-s1">{{dataOptions.zhsSp}}</span>
              <span> )</span>
            </div>
          </div>
        </div>
      </div>
      <div class="xqu-middle-2box-3" @click="() => this.$parent.getInfo('sqkj_sp')">
        <div class="xqu-middle-2box-3box">
          <span>商铺</span>
          <span>{{dataOptions.sp}}</span>
        </div>
        <div class="xqu-middle-2box-3box">
          <span>办公室</span>
          <span>{{dataOptions.bgs}}</span>
        </div>
        <div class="xqu-middle-2box-3box">
          <span>企业总数</span>
          <span>{{dataOptions.qyzs}}</span>
        </div>
      </div>
      <div class="xqu-middle-2box-4">
        <div class="xqu-middle-2box-4L" @click="() => this.$parent.getInfo('sqkj_cw')">
          <ul>
            <li>
              <span>{{dataOptions.cw}}</span>
              <span>车位</span>
            </li>
            <li>
              <span>{{dataOptions.jck}}</span>
              <span>出入口</span>
            </li>
            <li>
              <span>{{dataOptions.ljd}}</span>
              <span>垃圾点</span>
            </li>
            <li>
              <span>{{dataOptions.kdg}}</span>
              <span>快递柜</span>
            </li>
            <li>
              <span>{{dataOptions.zxcdz}}</span>
              <span>自行车桩</span>
            </li>
            <li>
              <span>{{dataOptions.qcdz}}</span>
              <span>汽车电桩</span>
            </li>
          </ul>
        </div>
        <div class="xqu-middle-2box-4R" @click="() => this.$parent.getInfo('sqkj_hs')">
          <ul>
            <li>
              <span>会所</span>
              <span>{{dataOptions.hs}}</span>
              <span>间</span>
            </li>
            <li>
              <span>广场</span>
              <span>{{dataOptions.gc}}</span>
              <span>间</span>
            </li>
            <li>
              <span>人工湖</span>
              <span>{{dataOptions.rgh}}</span>
              <span>间</span>
            </li>
            <li>
              <span>游泳池</span>
              <span>{{dataOptions.yyc}}</span>
              <span>间</span>
            </li>
            <li>
              <span>篮球场</span>
              <span>{{dataOptions.lqc}}</span>
              <span>间</span>
            </li>
            <li>
              <span>儿童游乐场</span>
              <span>{{dataOptions.etylc}}</span>
              <span>间</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    dataOptions:{
      type: Object,
      default(){
        return {};
      }
    }
  },
  components: {},
  data() {
    return {


    };
  },
  components: {},
  computed: {},
  created() {
//console.log(this.dataOptions);

  },

  methods: {},
};
</script>

<style scoped lang="scss">
.xqu-middle-box {
  width: 100%;
  height: 61vh;
  overflow: hidden;
  //   border: 1px solid tan;
  margin-top: 2vh;
  .xqu-middle-1 {
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
    color: rgba(255, 217, 1, 1);
    padding-left: 11.1vw;
    padding-top: 2.5vh;
  }
  .xqu-middle-2box {
    margin-top: 4vh;
    .xqu-middle-2box-1 {
      width: 81%;
      height: 10vh;
      overflow: hidden;
      //   border: 1px solid thistle;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 0 auto;
      .xqu-box1 {
        width: 33.3%;
        height: 4.5vh;
        // border: 1px solid thistle;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .xqu-box1-1 {
          width: 100%;
          color: #fff;
          text-align: right;
          font-size: 0.8vw;
          padding-right: 1vw;
        }
        .xqu-box1-2 {
          font-size: 0.6vw;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          img {
            margin-left: -1.1vw;
          }
          span {
            font-size: 1.2vw;
            font-family: Adobe Heiti Std;
            font-weight: bold;
            color: #fea61c;
            background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    .xqu-middle-2box-2 {
      width: 81%;
      height: 10vh;
      overflow: hidden;
      //   border: 1px solid tomato;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.8vh;
      .xqu-2box-2a-1 {
        width: 50%;
        height: 10vh;
        color: #fff;
        // border: 1px solid tan;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .xqu-2box-2a-1a {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          .xqu-2box-2a-1aL {
            img {
              margin-left: 0vw;
              margin-right: 0.7vw;
            }
          }
          .xqu-2box-2a-1aR {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 5.9vh;
            line-height: 3vh;
            span {
              &:first-child {
                font-size: .8vw;
              }
              &:last-child {
                width: 100%;
                font-size: 1.2vw;
                font-family: Adobe Heiti Std;
                font-weight: bold;
                color: #fea61c;
                background: -webkit-gradient(linear, right top, left top, from(rgba(64, 223, 203, 0.89)), to(rgba(0, 192, 250, 0.89)));
                background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        }
        .xqu-2box-2a-1b {
          color: #fff;
          font-size: 0.4vw;
          .xqu-2box-2a-1box {
             font-size: 0.7vw;
            .xqu-s1 {
              font-size: 0.7vw;
              font-family: Adobe Heiti Std;
              font-weight: bold;
              color: #ffffff;
              background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              padding-right: 0.1vw;
            }
            .xqu-s1a {
              padding-left: 2vw;
            }
          }
        }
      }
    }
    .xqu-middle-2box-3 {
      width: 81%;
      height: 7.5vh;
      overflow: hidden;
      //   border: 1px solid tomato;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 0.5vh;
      .xqu-middle-2box-3box {
        width: 18%;
        height: 6.5vh;
        line-height: 3vh;
        background: linear-gradient(183deg, #145dca, #1534c0);
        border-radius: 0.5vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        font-size: 0.8vw;
        span {
          &:last-child {
            font-size: 0.8vw;
            font-family: Adobe Heiti Std;
            font-weight: bold;
            color: #fea61c;
            background: -webkit-gradient(linear, right top, left top, from(rgba(64, 223, 203, 0.89)), to(rgba(0, 192, 250, 0.89)));
            background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    .xqu-middle-2box-4 {
      width: 81%;
      height: 21.5vh;
      overflow: hidden;
      //   border: 1px solid tomato;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 1vh;
      .xqu-middle-2box-4L {
        width: 50%;
        height: 21.5vh;
        overflow: hidden;
        // border: 1px solid tomato;
        ul li {
          width: 48%;
          height: 7vh;
          line-height: 2.6vh;
          //   border: 1px solid teal;
          float: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            color: #fff;
            &:first-child {
              font-size: 1.2vw;
              font-family: hy;
              font-weight: bold;
              font-style: italic;
              color: #fff7c8;
            }
            &:last-child {
              font-size: 0.6vw;
              font-weight: bold;
              font-style: normal;
              color: rgba(238, 171, 26, 1);
            }
          }
        }
      }
      .xqu-middle-2box-4R {
        width: 43%;
        height: 21.5vh;
        overflow: hidden;
        padding-top: 0.8vh;
        // border: 1px solid tomato;
        ul li {
          width: 100%;
          height: 3vh;
          line-height: 3vh;
          float: left;
          font-size: 0.8vw;
          span {
            &:nth-of-type(1) {
              display: inline-block;
              width: 50%;
              text-align: center;
              color: #ffffff;
            }
            &:nth-of-type(2) {
              display: inline-block;
              width: 33%;
              text-align: center;

              font-family: hy;
              font-weight: bold;
              font-style: italic;
              color: #ffd901;
            }
            &:nth-of-type(3) {
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>
