<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img
        class="back-ground-image"
        src="@/assets/image/idx-xiaoqu.png"
        alt=""
      />
      <!-- 顶部 -->
      <div class="header">数字东泰</div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <!-- Left -->
      <div class="idx-Left">
        <!-- 常住人口 -->
        <span @click="getInfo('czrk')">
          <PermanentPopulation :data-options="dataOptions"></PermanentPopulation>
        </span>
        <!-- 社区空间 -->
        <ShequKj :data-options="dataOptions"></ShequKj>
      </div>
      <!-- right -->
      <div class="idx-Right">
        <div class="idx-Right-top">
          <div class="idx-Right-top-L">
            <!-- <div class="idx-map-title">光大花园小区</div> -->
            <sun-map></sun-map>
          </div>
          <div class="idx-Right-top-R" @click="getInfo('aqfk')">
            <!-- 安全防控 -->
            <AnquanFk :data-options="dataOptions"></AnquanFk>
          </div>
          <div class="clear"></div>
        </div>
        <div class="idx-Right-bottom" @click="getList">
          <!-- 各小区常住人口对比 -->
          <XiaoqumenNum></XiaoqumenNum>
        </div>
      </div>
    </div>

    <dialog-view :dialog-title="'常住人口'" :on-submit="onSubmit" :dialog="czrk" :handle-close="() => this.czrk = false" >
      <ul class="dialog_form">
        <li class="form_item">
          <label>常住人口</label>
          <input type="text" v-model="data.czrk" />
        </li>
        <li class="form_item">
          <label>户籍人口</label>
          <input type="text" v-model="data.hzrk" />
        </li>
        <li class="form_item">
          <label>外籍人口</label>
          <input type="text" v-model="data.wjrk" />
        </li>
      </ul>
    </dialog-view>

    <!-- 面积 -->
    <dialog-view :dialog-title="'社区空间'" :on-submit="onSubmit" :dialog="sqkj_mj" :handle-close="() => this.sqkj_mj = false" >
      <ul class="dialog_form">
        <li class="form_item">
          <label>辖区面积</label>
          <input type="text" v-model="data.xqmj" />
        </li>
        <li class="form_item">
          <label>建筑面积</label>
          <input type="text" v-model="data.jzmj" />
        </li>
        <li class="form_item">
          <label>绿化面积</label>
          <input type="text" v-model="data.lhmj" />
        </li>
      </ul>
    </dialog-view>

    <!-- 栋数 -->
    <dialog-view :dialog-title="'社区空间'" :on-submit="onSubmit" :dialog="sqkj_ds" :handle-close="() => this.sqkj_ds = false" >
      <ul class="dialog_form">
        <li class="form_item">
          <label>高层</label>
          <input type="text" v-model="data.jzdsGc" />
        </li>
        <li class="form_item">
          <label>住宅</label>
          <input type="text" v-model="data.zhsZz" />
        </li>
        <li class="form_item">
          <label>别墅</label>
          <input type="text" v-model="data.jzdsBs" />
        </li>
        <li class="form_item">
          <label>商铺</label>
          <input type="text" v-model="data.zhsSp" />
        </li>
        <li class="form_item">
          <label>其它</label>
          <input type="text" v-model="data.jzdsQt" />
        </li>
      </ul>
    </dialog-view>

    <!-- 商铺 -->
    <dialog-view :dialog-title="'社区空间'" :on-submit="onSubmit" :dialog="sqkj_sp" :handle-close="() => this.sqkj_sp = false" >
      <ul class="dialog_form">
        <li class="form_item">
          <label>商铺</label>
          <input type="text" v-model="data.sp" />
        </li>
        <li class="form_item">
          <label>办公室</label>
          <input type="text" v-model="data.bgs" />
        </li>
        <li class="form_item">
          <label>企业总数</label>
          <input type="text" v-model="data.qyzs" />
        </li>
      </ul>
    </dialog-view>

    <!-- 车位 -->
    <dialog-view :dialog-title="'社区空间'" :on-submit="onSubmit" :dialog="sqkj_cw" :handle-close="() => this.sqkj_cw = false" >
      <ul class="dialog_form">
        <li class="form_item">
          <label>车位</label>
          <input type="text" v-model="data.cw" />
        </li>
        <li class="form_item">
          <label>出入口</label>
          <input type="text" v-model="data.jck" />
        </li>
        <li class="form_item">
          <label>垃圾点</label>
          <input type="text" v-model="data.ljd" />
        </li>
        <li class="form_item">
          <label>快递柜</label>
          <input type="text" v-model="data.kdg" />
        </li>
        <li class="form_item">
          <label>自行车桩</label>
          <input type="text" v-model="data.zxcdz" />
        </li>
        <li class="form_item">
          <label>汽车电桩</label>
          <input type="text" v-model="data.qcdz" />
        </li>
      </ul>
    </dialog-view>

    <!-- 会所 -->
    <dialog-view :dialog-title="'社区空间'" :on-submit="onSubmit" :dialog="sqkj_hs" :handle-close="() => this.sqkj_hs = false" >
      <ul class="dialog_form">
        <li class="form_item">
          <label>会所</label>
          <input type="text" v-model="data.hs" />
        </li>
        <li class="form_item">
          <label>广场</label>
          <input type="text" v-model="data.gc" />
        </li>
        <li class="form_item">
          <label>人工湖</label>
          <input type="text" v-model="data.rgh" />
        </li>
        <li class="form_item">
          <label>游泳池</label>
          <input type="text" v-model="data.yyc" />
        </li>
        <li class="form_item">
          <label>篮球场</label>
          <input type="text" v-model="data.lqc" />
        </li>
        <li class="form_item">
          <label>儿童游乐场</label>
          <input type="text" v-model="data.etylc" />
        </li>
      </ul>
    </dialog-view>

    <dialog-view :dialog-title="'安全防控'" :on-submit="onSubmit" :dialog="aqfk" :handle-close="() => this.aqfk = false" >
      <ul class="dialog_form">
        <li class="form_item">
          <label>治安岗亭</label>
          <input type="text" v-model="data.zagt" />
        </li>
        <li class="form_item">
          <label>安保人员</label>
          <input type="text" v-model="data.abry" />
        </li>
        <li class="form_item">
          <label>消火栓</label>
          <input type="text" v-model="data.xhs" />
        </li>
        <li class="form_item">
          <label>工作人员</label>
          <input type="text" v-model="data.gzry" />
        </li>
        <li class="form_item">
          <label>监控室</label>
          <input type="text" v-model="data.jks" />
        </li>
        <li class="form_item">
          <label>保洁人员</label>
          <input type="text" v-model="data.bjry" />
        </li>
        <li class="form_item">
          <label>监控头</label>
          <input type="text" v-model="data.jkt" />
        </li>
      </ul>
    </dialog-view>
    <dialog-view :dialog-title="'安全防控'" :on-submit="onBatchSubmit" :dialog="xqrk" :handle-close="() => this.xqrk = false" >
      <ul class="dialog_form">
        <li class="form_item" v-for="(item,index) in list" :key="index">
          <label>{{ item.gardenName }}</label>
          <input type="text" v-model="item.czrk" />
        </li>
      </ul>
    </dialog-view>
  </div>
</template>

<script>
import { listGarden,getGardenData, batchGarden } from "@/api/garden";
import dialogView from "@/components/dialog/dialogView";
import Demo from "@/views/xiaoqu/components/demo.vue";
import Date from "@/views/commonComponents/date.vue";
import SunMap from "@/views/xiaoqu/components/sunMap.vue";
import PermanentPopulation from "@/views/xiaoqu/components/permanentPopulation.vue";
import ShequKj from "@/views/xiaoqu/components/shequKj.vue";
import AnquanFk from "@/views/xiaoqu/components/anquanFk.vue";
import XiaoqumenNum from "@/views/xiaoqu/components/xiaoqumenNum.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";

export default {
  components: {
    Demo,
    Date,
    SunMap,
    PermanentPopulation,
    ShequKj,
    AnquanFk,
    XiaoqumenNum,
    rightNav,
    dialogView
  },
  data() {
    return {
      id: this.$route.query.mapPara,
      dataOptions: {},
      queryParams: {
        //gardenId: "5",
       gardenName: "汇业大厦",

      },
      czrk: false,
      sqkj_mj: false,
      sqkj_ds: false,
      sqkj_sp: false,
      sqkj_cw: false,
      sqkj_hs: false,
      aqfk: false,
      xqrk: false,
      data: {},
      list: [],
    };
  },
  created() {
   // console.log(this.id);
    getGardenData(this.id).then((response) => {
      this.dataOptions = response.data;
      // console.log('1111:',this.dataOptions);
    });
  },
  methods: {
    getInfo(open){
      if(!this.existSystem()){
        return false;
      }
      getGardenData(this.id).then(res => {
        this.data = res.data;
        this[open] = true;
      })
    },
    getList(){
      if(!this.existSystem){
        return false;
      }
      listGarden({}).then(res => {
        this.list = res.rows;
        this.xqrk = true;
      })
    },
    onSubmit(){
      this.data.jzds = parseInt(this.data.jzdsGc) + parseInt(this.data.jzdsBs) + parseInt(this.data.jzdsQt);
      this.data.zhs = parseInt(this.data.zhsZz) + parseInt(this.data.zhsSp);
      batchGarden([this.data]).then(res =>{
        this.$message('修改成功');
        setTimeout(()=>{
          window.location.reload();
        },2000);
      });
    },
    onBatchSubmit(){
      batchGarden(this.list).then(res =>{
        this.$message('修改成功');
        setTimeout(()=>{
          window.location.reload();
        },2000);
      });
    }
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // 顶部
  .header {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    letter-spacing: 4vw;
    padding-left: 1vw;
    // background: linear-gradient(to top, #c0d9ff, #fff);
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3vw;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    // color: #FFFFFF;
    text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
  }
  .time-box {
    width: 25vw;
    height: 7vh;
    // border: 1px solid teal;
    position: fixed;
    top: 7vh;
    right: 3vw;
    z-index: 99;
  }
  // Left
  .idx-Left {
    width: 26.59vw;
    height: calc(100% - 10vh);
    margin-right: 0vw;
    border: 1px solid teal;
    float: left;
  }

  .idx-Right {
    margin-right: 0vw;
    width: 70.8vw;
    float: left;
    // border: 1px solid tan;
    margin-left: 0vw;
    .idx-Right-top {
      width: 100%;
      height: 45vh;
      margin-top: 6vh;
      // border: 1px solid #c2663a;
      .idx-Right-top-L {
        width: 58.5%;
        height: 45vh;
        // border: 1px solid #c2663a;
        margin-left: 2.7vw;
        float: left;
        position: relative;
        .idx-map-title {
          width: 100%;
          height: 6vh;
          line-height: 6vh;
          text-align: center;
          font-size: 0.9vw;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffd901;
          //   border: 1px solid #c2663a;
        }
      }
      .idx-Right-top-R {
        width: 32%;
        height: 45vh;
        // border: 1px solid #c2663a;
        float: right;
        position: relative;
      }
    }
    .idx-Right-bottom {
      position: relative;
      width: 100%;
      height: 31vh;
      margin-top: 5.5vh;
      // border: 1px solid #c2663a;
    }
  }
}

</style>
<style scoped lang="scss">
.dialog_form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8vw;
  color: #007eff;

  .form_item {
    display: inline-block;
    width: 16vw;
    height: 6vh;
    display: flex;
    align-items: center;

    label {
      width: 6vw;
      line-height: 1;
      margin-right: 0.5vw;
      text-align: right;
    }

    input {
      height: 4vh;
      width: 12vw;
      background: #011428;
      border: 1px solid #1863bf;
      border-radius: 2px;
      color: #ffffff;
      padding-left: 0.6vw;
    }
  }
}
</style>
