<template>
  <div class="idx-left-box1">
    <div class="idx-Lbox-1">常住人口</div>
    <div class="idx-Lbox-2">
      <div class="idx-Lbox-2a">000000000</div>
      <div class="idx-Lbox-2b">{{dataOptions.czrk}}</div>
    </div>
    <div class="idx-Lbox-3">
      <div class="idx-Lbox-3L">
        <div class="idx-Lbox-3L-top">户籍人口</div>
        <div class="idx-Lbox-3L-bt">{{dataOptions.hzrk}}</div>
      </div>
      <div class="idx-Lbox-3R">
        <div class="idx-Lbox-3R-top">外籍人口</div>
        <div class="idx-Lbox-3R-bt">{{dataOptions.wjrk}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    dataOptions:{
      type: Object,
      default(){
        return {};
      }
    }
  },

  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.idx-left-box1 {
  width: 100%;
  height: 24vh;
  //   border: 1px solid tan;
  .idx-Lbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.5vw;
    padding-top: 3vh;
  }
  .idx-Lbox-2 {
    width: 87%;
    height: 7vh;
    line-height: 10vh;
    // border: 1px solid tomato;
    margin: 0 auto;
    position: relative;
    .idx-Lbox-2a {
      font-family: "hy";
      font-weight: bold;
      font-style: italic;
      font-size: 2vw;
      color: #ffd901;
      opacity: 0.1;
      letter-spacing: 1.255vw;
      height: 7vh;
      padding-left: 1.5vw;
      line-height: 7vh;
      position: relative;
      z-index: 0;
    }
    .idx-Lbox-2b {
      position: absolute;
      top: 0vh;
      left: -0.75vw;
      font-family: "hy";
      font-style: italic;
      font-weight: bold;
      font-size: 2vw;
      color: #ffd901;
      opacity: 1;
      letter-spacing: 1.2vw;
      padding-left: 1.5vw;
      height: 7vh;
      line-height: 7vh;
      text-align: right;
      width: 100%;
      z-index: 999;
      //   display: none;
    }
  }
  .idx-Lbox-3 {
    width: 88%;
    height: 10vh;
    /* line-height: 7vh; */
    // border: 1px solid tan;
    font-size: 2vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .idx-Lbox-3L {
      margin-right: 5vw;
      margin-left: 2vw;
      .idx-Lbox-3L-top {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
        padding-bottom: 1vh;
      }
      .idx-Lbox-3L-bt {
        font-size: 1.7vw;
        font-family: zcoolqingkehuangyouti;
        font-weight: bold;
        font-style: italic;
        color: #eeab1a;
        font-family: "hy";
      }
    }
    .idx-Lbox-3R {
      margin-right: 0vw;
      margin-left: 2.8vw;
      .idx-Lbox-3R-top {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
        padding-bottom: 1vh;
      }
      .idx-Lbox-3R-bt {
        font-size: 1.7vw;
        font-family: zcoolqingkehuangyouti;
        font-weight: bold;
        font-style: italic;
        color: #eeab1a;
        font-family: "hy";
      }
    }
  }
}
</style>
