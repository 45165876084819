<template>
  <div class="">
    <!-- 弹框 -->
    <el-dialog
        title=""
        :visible.sync="dialog"
        :width="width"
        :append-to-body="true"
        :before-close="close"
        :close-on-click-modal="false"
    >
      <div class="dialog-bg">
        <img class="back-ground-image" src="@/assets/image/idx-tk.png" alt=""/>
      </div>
      <!--  -->
      <div class="dialog-box">
        <div class="dialog-box-top">{{ dialogTitle }}</div>
        <div class="dialog-box-bottom">
          <slot></slot>
          <div class="dialog_btn" style="display: block">
            <div style="margin-right: 50px" @click="close">取消</div>
            <div @click="onSubmit">确定修改</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    dialog:{type: Boolean, default: false},
    dialogTitle:{type: String,default: "提示"},
    onSubmit:{type: Function,default: ()=>{
        console.log(111)}
    },
    handleClose:{
      type: Function, default: ()=>{
        return true;
      }
    },
    width:{
      type: String, default: '46vw'
    }
  },
  components: {},
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    /*onSubmit() {
      console.log("确定修改", this.dialogTitle, this.dataList);
    },*/
    /*cancel_dialog() {
      this.$parent.edit_dialog = false;
    },*/
    close(done) {
      // done();
      if(this.handleClose())
        this.$parent.edit_dialog = false;
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 9999;
  width: 50%;
  height: 66vh;
  // background: url();
  background: rgba(255, 255, 255, 0);
  // border: 1px solid teal !important;
  .el-dialog__header {
    /* padding: 20px 20px 10px; */
    padding-top: 4vh;
  }

  .el-dialog__body {
    padding: 0.2vw 0.2vh;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }

  .el-dialog__headerbtn {
    width: 4vw;
    height: 5vh;
    // border: 1px solid teal !important;
    position: absolute;
    top: 5.5vh;
    right: -0.4vw;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    opacity: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 999999;
  }

  .dialog-bg {
    position: relative;
  }

  .back-ground-image {
    width: 100%;
    height: 48vh;
    position: relative;
    // border: 1px solid tan;
  }

  .dialog-box {
    position: absolute;
    top: 6.5vh;
    left: 0;
    padding: 0 2.3vw;
    box-sizing: border-box;
    width: 100%;
    // height: 59vh;
    // overflow: hidden;
    // border: 1px solid thistle;
    .dialog-box-top {
      width: 100%;
      height: 6vh;
      line-height: 4vh;
      text-align: center;
      // border: 1px solid thistle;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffd901;
    }

    .dialog-box-bottom {
      width: 100%;
      height: 26vh;
      overflow-y: scroll;
      // border: 1px solid tan;
      padding-right: 2vw;
      margin-top: 2vh;

      .video-iframe {
        width: 100%;
        height: 49vh;
        // border: 1px solid tan;
      }
    }
  }
}

.dialog_form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8vw;
  color: #007eff;

  .form_item {
    display: inline-block;
    width: 16vw;
    height: 6vh;
    display: flex;
    align-items: center;

    label {
      width: 6vw;
      line-height: 1;
      margin-right: 0.5vw;
      text-align: right;
    }

    input {
      height: 4vh;
      width: 12vw;
      background: #011428;
      border: 1px solid #1863bf;
      border-radius: 2px;
      color: #ffffff;
      padding-left: 0.6vw;
    }
  }
}

.dialog_btn {
  // background: red;
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  // top: 30vh;
  bottom: -10vh;

  div {
    display: inline-block;
    width: 9.3vw;
    height: 4.6vh;
    line-height: 4.6vh;
    background: #002953;
    border: 1px solid #1863BF;
    border-radius: .2vw;
    color: #24D8D7;
    cursor: pointer;
  }
}
</style>
